@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

/* Josefin Sans */
@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@400;700&display=swap');

/* Lexend */
@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@200;700&display=swap');











































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































body {
  margin: 0;
  font-family: 'Lexend', -apple-system, BlinkMacSystemFont,
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #353535 !important;
}

/* Apply Josefin Sans to headings (h1, h2, h3, etc.) */
h1, h2, h3, h4, h5, h6 {
  font-family: 'Josefin Sans', sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
  color: #353535 !important;
}

